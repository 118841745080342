import React from 'react';
import {
  StyledContainer,
  StyledSection,
  Heading,
  Text,
  TopDots,
} from '@styled/components';
import { useStaticQuery, graphql } from 'gatsby';
import { LineChart } from '@styled-icons/boxicons-regular/LineChart';
import { UserSmile } from '@styled-icons/remix-line/UserSmile';
import { NetworkChart } from '@styled-icons/boxicons-solid/NetworkChart';
import { TransferAlt } from '@styled-icons/boxicons-regular/TransferAlt';
import {
  TopMiniFeature,
  MiniFeature,
  MiniFeaturesContainer,
  GridContainer,
} from './features.style';

function iconGenerator(icon) {
  switch (icon) {
    case 'LineChart':
      return <LineChart color="#45D68B" />;
    case 'TransferAlt':
      return <TransferAlt color="#45D68B" />;
    case 'UserSmile':
      return <UserSmile color="#45D68B" />;
    case 'NetworkChart':
      return <NetworkChart color="#45D68B" />;
    default:
      break;
  }
}

const Features = () => {
  const data = useStaticQuery(graphql`
    query allFeaturesSectionJson {
      featuresSectionJson {
        title
        image {
          base
        }
        gridItems
        miniFeatures {
          title
          icon
        }
      }
    }
  `);

  const { title, gridItems, miniFeatures, image } = data.featuresSectionJson;

  return (
    <StyledSection>
      <TopDots image={image} marginTop="20px" />
      <StyledContainer>
        <Heading width="100%" margin="100px 0 0 0" zIndex="1">
          {title}
        </Heading>
        <GridContainer>
          {gridItems.map((grid, index) => (
            <Text key={index}>{grid}</Text>
          ))}
        </GridContainer>
        <MiniFeaturesContainer>
          {miniFeatures.map((mini, index) => (
            <MiniFeature key={index}>
              <TopMiniFeature>{iconGenerator(mini.icon)}</TopMiniFeature>
              <Text lineHeight="24px">{mini.title}</Text>
            </MiniFeature>
          ))}
        </MiniFeaturesContainer>
      </StyledContainer>
    </StyledSection>
  );
};

export default Features;
