import React from 'react';
import {
  StyledContainer,
  StyledSection,
  Heading,
  SubHeading,
  TopDots,
} from '@styled/components';
import Image from '@components/image';

import { useStaticQuery, graphql } from 'gatsby';
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import {
  VerticalDivider,
  KeyFeatureTextWrapper,
  CheckItemContainer,
  KeyFeature,
  KeyFeaturesContainer,
  InnerKeyFeaturesContainer,
  ImageContainer,
} from './keyFeatures.style';

const KeyFeatures = () => {
  const data = useStaticQuery(graphql`
    query allKeyFeaturesSectionJson {
      keyFeaturesSectionJson {
        title
        subTitle
        image {
          base
        }
        keyFeatures {
          title
          description
        }
        keyFeaturesImage {
          base
        }
      }
    }
  `);

  const {
    title,
    subTitle,
    keyFeatures,
    image,
    keyFeaturesImage,
  } = data.keyFeaturesSectionJson;

  return (
    <InnerKeyFeaturesContainer>
      <TopDots image={image} marginTop="-60px" />
      <StyledContainer isRow mobileIsColumnReverse>
        <StyledSection>
          <StyledContainer>
            <Heading width="100%" zIndex="1">
              {title}
            </Heading>
            <SubHeading
              textAlign="left"
              fontWeight="light"
              fontSize="24px"
              margin="24px 0 0 0"
              width="380px"
              zIndex="1"
            >
              {subTitle}
            </SubHeading>
          </StyledContainer>
          <StyledContainer>
            <KeyFeaturesContainer>
              {keyFeatures.map((mini, index) => (
                <KeyFeature key={index}>
                  <CheckItemContainer>
                    <CheckCircle color="#45D68B" />
                    <VerticalDivider />
                  </CheckItemContainer>
                  <KeyFeatureTextWrapper>
                    <p>{mini.title}</p>
                    <span>{mini.description}</span>
                  </KeyFeatureTextWrapper>
                </KeyFeature>
              ))}
            </KeyFeaturesContainer>
          </StyledContainer>
        </StyledSection>
        <ImageContainer hideMobile>
          <Image filename={keyFeaturesImage.base} alt="keyFeaturesImage" />
        </ImageContainer>
      </StyledContainer>
    </InnerKeyFeaturesContainer>
  );
};

export default KeyFeatures;
