import React from 'react';
import { openPopupWidget } from 'react-calendly';
import PropTypes from 'prop-types';

const DemoButton = ({ url, prefill, pageSettings, utm, className, title }) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  return (
    <button className={className} type="button" onClick={onClick}>
      {title}
    </button>
  );
};

DemoButton.propTypes = {
  url: PropTypes.string,
  prefill: PropTypes.object,
  pageSettings: PropTypes.object,
  utm: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};

function ScheduleADemoButton({ className }) {
  return (
    <DemoButton
      title="Schedule a demo"
      url="https://calendly.com/picortex/demo"
      utm="PiCortex website"
      className={
        className ||
        'w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-200 md:py-4 md:text-lg md:px-10 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out'
      }
      pageSettings={{
        backgroundColor: '#ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '#45D68B',
        textColor: '#4d5055',
      }}
    />
  );
}

ScheduleADemoButton.propTypes = {
  className: PropTypes.string,
};
export default ScheduleADemoButton;
