import React from 'react';
import {
  StyledContainer,
  StyledSection,
  Heading,
  SubHeading,
  Text,
  TopDots,
  TWButton,
} from '@styled/components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Check } from '@styled-icons/boxicons-regular/Check';

import Image from '@components/image';
import Customers from '@containers/Customers';
import {
  MiniIndustry,
  Industry,
  IndustriesSectionContainer,
  IndustriesTextWrapper,
  IndustriesContainer,
  ImageContainer,
} from './industries.style';

const Industries = () => {
  const data = useStaticQuery(graphql`
    query allIndustriesSectionJson {
      industriesSectionJson {
        title
        subTitle
        description
        industries
        image {
          base
        }
        industryImage {
          base
        }
      }
    }
  `);

  const {
    title,
    subTitle,
    description,
    industries,
    image,
    industryImage,
  } = data.industriesSectionJson;

  return (
    <IndustriesContainer className="py-4">
      <TopDots image={image} marginTop="-60px" />
      <StyledContainer isRow mobileIsColumn>
        <StyledSection>
          <StyledContainer>
            <Heading width="100%" zIndex="1">
              {title}
            </Heading>
            <IndustriesTextWrapper>
              <SubHeading
                textAlign="left"
                fontWeight="light"
                fontSize="24px"
                margin="4px 0 20px 0"
                zIndex="1"
              >
                {subTitle}
              </SubHeading>
              <Text
                color="secondaryTextColorLight"
                lineHeight="28px"
                fontSize="18px"
              >
                {description}
              </Text>
            </IndustriesTextWrapper>
          </StyledContainer>
          <StyledContainer>
            <IndustriesSectionContainer>
              <Text>Industries</Text>
              {industries.map((industry, index) => (
                <Industry key={index}>
                  <MiniIndustry>
                    <Check color="#fff" />
                  </MiniIndustry>
                  <p>{industry}</p>
                </Industry>
              ))}
              <div
                className="mt-3 rounded-md shadow sm:mt-0"
                style={{ maxWidth: '373px' }}
              >
                <Link
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primaryColor hover:bg-green-500 hover:text-white focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  to="/industries"
                >
                  View all industries
                </Link>
              </div>
            </IndustriesSectionContainer>
          </StyledContainer>
        </StyledSection>
        <ImageContainer>
          <Image filename={industryImage.base} alt="industry" />
        </ImageContainer>
      </StyledContainer>
      {/* <Customers /> */}
    </IndustriesContainer>
  );
};

export default Industries;
