import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Modal from '@utils/Modal';
import PropTypes from 'prop-types';
import ScheduleADemoButton from '@components/ScheduleADemoButton';
import Image from '@components/image';

const Hero = () => {
  const data = useStaticQuery(graphql`
    query allHeroSectionJson {
      heroSectionJson {
        title
        subTitle
        image {
          base
        }
      }
    }
  `);

  const { title, subTitle, image } = data.heroSectionJson;
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  return (
    <div className="relative bg-primaryColor overflow-hidden">
      <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
        <main className="mt-16 mx-auto max-w-screen-xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="mt-1 block text-4xl tracking-tight font-bold sm:text-5xl xl:text-6xl">
                  <span className="block text-gray-100">{title}</span>
                </span>
              </h1>
              <p className="mt-3 text-lg text-gray-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {subTitle}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <ScheduleADemoButton />
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <button
                    className="w-full inline-flex items-center justify-center px-3 py-3 text-base leading-6 font-medium group rounded-md text-gray-200 border-solid border-2 border-gray-200 hover:border hover:border-white hover:bg-white hover:text-gray-600 focus:outline-none transition duration-150 ease-in-out"
                    onClick={(e) => {
                      e.preventDefault();
                      setVideoModalOpen(true);
                    }}
                    aria-controls="modal"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 fill-current text-gray-200 group-hover:text-primaryColor flex-shrink-0"
                    >
                      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                      <path d="M10 17l6-5-6-5z" />
                    </svg>
                    <span className="ml-3">Watch our demo video</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                {/* <rect
                  y={72}
                  width={640}
                  height={640}
                  className="text-gray-50"
                  fill="currentColor"
                /> */}
                <rect
                  x={118}
                  width={404}
                  height={784}
                  fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                />
              </svg>
              <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setVideoModalOpen(true);
                  }}
                  aria-controls="modal"
                  type="button"
                  className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <span className="sr-only">Watch our video to learn more</span>

                  {/* <img
                    className="w-full"
                    src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1610360878/PiCortex%20Website/hero_s3rgss.png"
                    // src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                    alt=""
                  /> */}
                  <Image filename={image.base} alt="industry" />

                  <div
                    className="absolute inset-0 w-full h-full flex items-center justify-center"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-20 w-20 text-green-900 hover:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 84 84"
                    >
                      <circle
                        opacity="0.9"
                        cx={42}
                        cy={42}
                        r={42}
                        fill="text-gray-200"
                      />
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <Modal
            id="modal"
            ariaLabel="modal-headline"
            show={videoModalOpen}
            handleClose={() => setVideoModalOpen(false)}
          >
            <div className="relative pb-9/16">
              <iframe
                height="600px"
                className="w-full"
                src="https://www.youtube.com/embed/cCWJg8xOX5s"
                title="PiCortex demo video"
                allowFullScreen
              ></iframe>
            </div>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default Hero;
