import PageLayout from '@global/page-layout';
import React from 'react';
import Hero from '@containers/Hero';
import Benefits from '@containers/Benefits';
import Why from '@containers/Why';
import KeyFeatures from '@containers/KeyFeatures';
import CTA from '@containers/CTA';
import Features from '@containers/Features';
import Industries from '@containers/Industries';
import Partners from '@containers/Partners';
import IntroVideo from '@containers/IntroVideo';

const LandingPage = () => (
  <PageLayout title="Home">
    <Hero />
    {/* <IntroVideo /> */}
    <Why />
    <Features />
    <Benefits />
    <KeyFeatures />
    <Industries />
    <Partners />
    {/* <CTA /> */}
  </PageLayout>
);

export default LandingPage;
