import styled from 'styled-components';
import { widthUntil } from '@theme/theme';

const IndustriesContainer = styled.div`
  background: ${({ theme }) => theme.color.primaryBackground};
`;
// padding-top: 100px;

const IndustriesTextWrapper = styled.div`
  width: 440px;
  align-self: normal;
  ${widthUntil.MOBILE`
      width: 100%;
  `}
`;

const IndustriesSectionContainer = styled.div`
  margin-top: 40px;
  align-self: normal;
  margin-bottom: 100px;
`;

const Industry = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.color.lightBackground};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.white};
  margin-bottom: 16px;
  max-width: 373px;
`;

const MiniIndustry = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 3.2px;
  background-color: ${({ theme }) => theme.color.primaryColor};
  margin: 9px 12px 9px 9px;
`;

const ImageContainer = styled.div`
  height: auto;
  width: 100%;
  ${widthUntil.MOBILE`
      margin-bottom: 40px;
  `}
`;

export {
  MiniIndustry,
  Industry,
  IndustriesSectionContainer,
  IndustriesTextWrapper,
  IndustriesContainer,
  ImageContainer,
};
