import React from 'react';
import { IntercomAPI } from 'react-intercom';

const Partners = () => (
  // <div className="bg-gray-200">
  //   <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
  //     <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
  //       <div>
  //         <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
  //           Our partners
  //         </h2>
  //         <p className="mt-3 max-w-3xl text-lg leading-7">
  //           Be a part of the key partners enabling business of the future.
  //           Join the PiCortex network today as an integration partner or
  //           distributor.
  //         </p>
  //         <div className="mt-8 sm:flex">
  //           <div className="rounded-md shadow">
  //             <a
  //               onClick={() => IntercomAPI('show')}
  //               className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primaryColor hover:bg-green-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
  //             >
  //               Contact sales
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
  //         <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
  //           <img
  //             className="h-12"
  //             src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731845/Partners/fetola_us0sqz.png"
  //             alt="FETOLA"
  //           />
  //         </div>
  //         <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
  //           <img
  //             className="h-12"
  //             src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731843/Partners/edge_fenyqo.png"
  //             alt="Edge Growth"
  //           />
  //         </div>
  //         <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
  //           <img
  //             className="h-12"
  //             src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731844/Partners/bora_color_stjcaz.png"
  //             alt="Bora Growth Partners"
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  <div className="bg-gray-300">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="align-middle grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
        <h3 className="align-middle text-2xl font-medium text-center title-font text-gray-900 mb-4">
          Our partners
        </h3>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731845/Partners/fetola_us0sqz.png"
            alt="FETOLA"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731843/Partners/edge_fenyqo.png"
            alt="Edge Growth"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1599585937/Partners/cdi-logo_n6qzno.png"
            alt="CDI Capital"
          />
        </div>
        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            className="h-12"
            src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1596731844/Partners/bora_color_stjcaz.png"
            alt="Bora Growth Partners"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Partners;
