import React from 'react';
import {
  StyledContainer,
  StyledSection,
  Heading,
  Text,
  Divider,
  CheckItemContainer,
  Icon,
} from '@styled/components';
import { useStaticQuery, graphql } from 'gatsby';
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import { CheckItem, GridContainer } from './why.style';

const Why = () => {
  const data = useStaticQuery(graphql`
    query allWhySectionJson {
      whySectionJson {
        title
        gridItems
      }
    }
  `);

  const { title, gridItems } = data.whySectionJson;
  // TODO: Add background pattern
  return (
    <StyledSection backgroundColor="white">
      <StyledContainer isColumn>
        <Heading width="100%" margin="100px 0 0 0">
          {title}
        </Heading>
        <GridContainer>
          {gridItems.map((grid, index) => (
            <CheckItemContainer key={index}>
              <CheckItem>
                <CheckCircle color="#45D68B" size="25" />
              </CheckItem>
              <Text margin="0">{grid}</Text>
            </CheckItemContainer>
          ))}
        </GridContainer>
        <Divider />
      </StyledContainer>
    </StyledSection>
  );
};

Why.propTypes = {};

export default Why;
