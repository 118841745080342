import styled from 'styled-components';

const GridContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 81px;
`;

const CheckItem = styled.span.attrs({
  className:
    'w-8 h-8 mr-2 rounded-full inline-flex items-center justify-center',
})``;

export { CheckItem, GridContainer };
