import styled from 'styled-components';
import { widthUntil } from '@theme/theme';

const CTATextWrapper = styled.div`
  width: 380px;
  align-self: normal;
  margin-top: 100px;
  color: ${({ theme }) => theme.color.white};
  ${widthUntil.MOBILE`
      width: 100%;
  `}
`;

const CTAContainer = styled.div`
  width: 100%;
  margin-bottom: 100px;
  align-self: normal;
`;

const CTABlock = styled.div`
  align-self: normal;
  align-items: start;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  svg {
    width: 30px;
    height: 30px;
  }
`;

export { CTABlock, CTAContainer, CTATextWrapper };
