import styled from 'styled-components';
import { widthUntil } from '@theme/theme';

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;
  ${widthUntil.MOBILE`
      flex-direction: column;
      align-content: center;
      align-items: center;
  `}
  p {
    width: 45%;
    ${widthUntil.MOBILE`
       width: 100%;
  `}
  }
`;
// align-items: center;

const MiniFeaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;
  margin-bottom: 100px;
  ${widthUntil.MOBILE`
      flex-direction: column;
  `}
`;

const MiniFeature = styled.div`
  box-sizing: border-box;
  height: 221px;
  width: 241px;
  border: 1px solid ${({ theme }) => theme.color.lightBackground};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.primaryBackground};
  padding: 17px 0 0 17px;
  margin-right: 20px;
  ${widthUntil.MOBILE`
     margin-bottom: 20px;
     margin-right: 0;
     width: 100%;
  `}
`;

const TopMiniFeature = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 12px;
  margin-bottom: 52px;
`;
// background-color: ${({ theme }) => theme.color.primaryBackgroundLight};

export { TopMiniFeature, MiniFeature, MiniFeaturesContainer, GridContainer };
