import { widthUntil } from '@theme/theme';

import styled from 'styled-components';

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  ${widthUntil.TABLET_LARGE`
    width: 50%;
  `}
`;

const ImageContainer = styled.div`
  height: auto;
  width: 100%;
`;
// box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);

const HeaderWrapper = styled.div`
  margin-right: 20px;
  width: 100%;
  align-self: center;
  ${widthUntil.MOBILE`
      margin-right: 0;
  `}
`;

const FormContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

export { ImageContainer, HeaderWrapper, Column, FormContainer };
