import styled from 'styled-components';
import { widthUntil } from '@theme/theme';

const BenefitsContainer = styled.div`
  background: ${({ theme }) => theme.color.primaryBackground};
`;
const InnerBenefitsContainer = styled.div`
  padding-top: 100px;
`;

const MiniBenefitsContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 100px;
`;

const MiniBenefit = styled.div`
  height: 246px;
  width: 45%;
  float: left;
  box-sizing: border-box;
  margin-right: 24px;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.color.lightBackground};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 25px 25px 0 25px;

  ${widthUntil.MOBILE`
      width: 100%;
      height: 100%;
  `}
  p {
    color: ${({ theme }) => theme.color.textColor};
    font-size: 20px;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 9px;
  }
  span {
    color: ${({ theme }) => theme.color.secondaryTextColorLight};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
`;

const TopMiniBenefit = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 12px;
  margin-bottom: 24px;
`;
// background-color: ${({ theme }) => theme.color.primaryBackgroundLight};

export {
  TopMiniBenefit,
  MiniBenefit,
  MiniBenefitsContainer,
  InnerBenefitsContainer,
  BenefitsContainer,
};
