import styled from 'styled-components';

const InnerKeyFeaturesContainer = styled.div`
  padding-top: 100px;
`;

const KeyFeaturesContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 100px;
`;
// width: 45%;

const KeyFeature = styled.div`
  margin-bottom: 40px;
  display: flex;
  p {
    color: ${({ theme }) => theme.color.textColor};
    font-size: 20px;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 16px;
  }
  span {
    color: ${({ theme }) => theme.color.secondaryTextColorLight};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
`;

const CheckItemContainer = styled.div`
  align-self: normal;
  display: flex;
  flex-direction: column;
  svg {
    width: 30px;
    height: 30px;
  }
`;
const KeyFeatureTextWrapper = styled.div`
  margin-left: 24px;
`;
const VerticalDivider = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.lightBackground};
  box-sizing: border-box;
  height: 100%;
  align-self: center;
`;

const ImageContainer = styled.div`
  height: auto;
  width: 100%;
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'unset')};
`;

export {
  VerticalDivider,
  KeyFeatureTextWrapper,
  CheckItemContainer,
  KeyFeature,
  KeyFeaturesContainer,
  InnerKeyFeaturesContainer,
  ImageContainer,
};
