import React from 'react';
import {
  StyledContainer,
  StyledSection,
  Heading,
  Divider,
  SubHeading,
  TopDots,
} from '@styled/components';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '@components/image';

import {
  TopMiniBenefit,
  MiniBenefit,
  MiniBenefitsContainer,
  InnerBenefitsContainer,
  BenefitsContainer,
} from './benefits.style';

const Benefits = () => {
  const data = useStaticQuery(graphql`
    query allBenefitsSectionJson {
      benefitsSectionJson {
        title
        subTitle
        image {
          base
        }
        miniBenefits {
          title
          description
          icon {
            base
          }
        }
      }
    }
  `);

  const { title, subTitle, miniBenefits, image } = data.benefitsSectionJson;

  return (
    <BenefitsContainer>
      <Divider />
      <TopDots image={image} marginTop="20px" />

      <InnerBenefitsContainer>
        <StyledSection>
          <StyledContainer>
            <Heading width="100%" zIndex="1">
              {title}
            </Heading>
            <SubHeading
              textAlign="left"
              fontWeight="light"
              fontSize="24px"
              margin="24px 0 0 0"
              width="380px"
              zIndex="1"
            >
              {subTitle}
            </SubHeading>
          </StyledContainer>
          <StyledContainer>
            <MiniBenefitsContainer>
              {miniBenefits.map((mini, index) => (
                <MiniBenefit key={index}>
                  <TopMiniBenefit>
                    <Image filename={mini.icon.base} alt="dots" />
                  </TopMiniBenefit>
                  <p>{mini.title}</p>
                  <span>{mini.description}</span>
                </MiniBenefit>
              ))}
            </MiniBenefitsContainer>
          </StyledContainer>
        </StyledSection>
      </InnerBenefitsContainer>
    </BenefitsContainer>
  );
};

export default Benefits;
